import React, {useCallback, useEffect, useState} from 'react'
import Login, { config } from '../components/login'
import { navigate } from "gatsby"

const Account = () =>  {
  const [signIn, setSignIn] = useState(null)

  const [ user, setUser] = useState(false)
  const logout = useCallback(async () => {
    try {
      await signIn.authClient.signOut()
      setUser(false)
    } catch(error) {
      console.error('Sign out error: ' + error)
    }
  }, [signIn])

  const asyncUseEffect = useCallback(async () => {
    const OktaSignIn = await import("@okta/okta-signin-widget")
    const signInFn = new OktaSignIn.OktaSignIn(config);
    const token = await signInFn.authClient.tokenManager.get('idToken');
    if (token) {
      setUser(token.claims.name)
    } else {
      // Token has expired
      setUser(false)
    }
    setSignIn(signInFn)
  }, [])

  useEffect(() => {
    asyncUseEffect()
  }, [asyncUseEffect])

  if (!user) {
    return (
      <Login/>
    );
  }

  return (
    <>
      Logged in as {user}<br/>
      <button onClick={logout}>LOGOUT</button>
    </>
  )
}

export default Account
